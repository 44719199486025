import React, { Component, useEffect } from "react";
import {doc, setDoc, collection, getDoc, addDoc, DocumentReference, updateDoc} from "firebase/firestore";
import { db } from '../../firebase/db.js';
import { useState, useForm } from 'react-hook-form';
import { getAuth } from 'firebase/auth';
import { Spinner } from 'react-bootstrap';
import GetSweatTable from './getSweatTable.js';
import {useParams} from "react-router-dom";


function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;

}

function MyComponent(props){
    useEffect(()=>{
        this.setState({showQuestions:'hide-questions'})
    }, []) // <-- empty dependency array
    return <div></div>
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class AddRacePlan extends Component {
    constructor(props){
        super(props);

        this.nextSweatTestNumber = 0;
        this.carb = React.createRef();
        this.fluid = React.createRef();
        this.sodium = React.createRef();
        this.carb_value = React.createRef();
        this.fluid_value = React.createRef();
        this.sodium_value = React.createRef();
        this.prodIndex = React.createRef();
        this.race_type = React.createRef();
        this.bike_est_time = React.createRef();
        this.run_est_time = React.createRef();
        this.race_time = React.createRef();
        this.questions = React.createRef();
        this.carb_new = '';
        this.fluid_new = '';
        this.sodium_new = '';
        this.carb_value_new = '';
        this.fluid_value_new = '';
        this.sodium_value_new = '';
        this.gutid = '';
        this.sodium_loss = '';
        this.sodium_consume = '';


        this.state = {
            value: '',
            bikeArray: [],
            runArray: [],
            sodium_consume:'No',
            sodium_loss_new:'',
            sodium_loss_value:'',
            carb_consume:'yes',
            carb_consume_value: '',
            fluid_consume: '',
            fluid_value: '',
            showSodium: '',
            showCarbs: '',
            showFluids: '',
            showQuestions: 'hide-questions',
            showEst: 'hide-est',
        };
        this.state = {loading: false}
        this.raceObject = {
            ['race_name']: 0,
            ['est_time']: 0,
            ['race_temp']: 0,
            ['race_date']: '',
            ['race_start_time']: '',
            ['race_humidity']: '',
            ['race_type']: 'triathlon',
            ['timestamp']: '',
            ['bike_est_time']: '',
            ['run_est_time']: '',
            ['current_weight']: '',

        };


        this.addRaceSubmit = this.addRaceSubmit.bind(this);
        this.raceNameInput = this.raceNameInput.bind(this);
        this.raceTimeInput = this.raceTimeInput.bind(this);
        this.currentWeightInput = this.currentWeightInput.bind(this);
        this.raceTempInput = this.raceTempInput.bind(this);
        this.raceDateInput = this.raceDateInput.bind(this);
        this.raceHumidityInput = this.raceHumidityInput.bind(this);
        this.raceTypeInput = this.raceTypeInput.bind(this);
        this.raceStartInput = this.raceStartInput.bind(this);
        this.raceEstBike = this.raceEstBike.bind(this);
        this.raceEstRun = this.raceEstRun.bind(this);
        this.addGutSubmit = this.addGutSubmit.bind(this);
        //this.componentDidMount = this.componentDidMount();
        this.showHideSelect = this.showHideSelect.bind(this);
        this.startHideSelect = this.startHideSelect.bind(this);

    }

    componentDidMount = async () => {
        this.startHideSelect();
    }


    raceNameInput(event){
        this.raceObject.race_name = event.target.value

    }

    raceTimeInput(event){
        this.raceObject.race_time = event.target.value

    }
    raceTempInput(event){
        this.raceObject.race_temp = event.target.value

    }
    raceDateInput(event){
        this.raceObject.race_date = event.target.value

    }
    raceHumidityInput(event){
        this.raceObject.race_humidity = event.target.value

    }
    raceTypeInput(event){
        this.raceObject.race_type = event.target.value
        console.log(this.raceObject);
    }

    raceEstBike(event){
        console.log(event.target.value)
        this.raceObject.bike_est_time = event.target.value
        console.log(this.raceObject);
    }
    raceEstRun(event){
        console.log(event.target.value)
        this.raceObject.run_est_time = event.target.value
        console.log(this.raceObject);
    }

    raceStartInput(event){
        this.raceObject.race_start_input = event.target.value
        console.log(this.raceObject);
    }

    currentWeightInput(event){
        this.raceObject.current_weight = event.target.value
        console.log(this.raceObject);
    }

    addGutSubmit = async (e) => {
        e.preventDefault();


        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        let carb = this.carb.current.value;

        // if current value is undefined then use the defaultValue (which comes in from firebase database)
        if(carb === undefined){
            this.carb_new = this.carb.current.defaultValue;
        }else{
            this.carb_new = carb;
        }
        //console.log(this.carb_new);

        let sodium = this.sodium.current.value;

        if(sodium === undefined){
            this.sodium_new = this.sodium.current.defaultValue;
        }else{
            this.sodium_new = sodium
        }


        let fluid = this.fluid.current.value;

        if(fluid === undefined){
            this.fluid_new = this.fluid.current.defaultValue;
        }else{
            this.fluid_new = fluid;
        }

        let carb_value = this.carb_value.current.value;
        if(carb_value === undefined){
            this.carb_value_new = this.carb_value.current.defaultValue;
        }else{
            this.carb_value_new = carb_value;
        }

        let sodium_value = this.sodium_value.current.value;
        if(sodium_value === undefined){
            this.sodium_value_new = this.sodium_value.current.defaultValue;
        }else{
            this.sodium_value_new = sodium_value;
        }

        let fluid_value = this.fluid_value.current.value;

        if(fluid_value === undefined){
            this.fluid_value_new = this.fluid_value.current.defaultValue;
        }else{
            this.fluid_value_new = fluid_value;
        }


        // update the gut training per param ID
        await updateDoc(doc(db, 'users', userID, 'gut-training', id), {
            sodium_consume: this.sodium_new,
            sodium_loss_value: this.sodium_value_new,
            carb_consume: this.carb_new,
            carb_consume_value:this.carb_value_new,
            fluid_consume:this.fluid_new,
            fluid_value:this.fluid_value_new,
            sodium_question:this.questions.current.value,
        }).then(() => {

                console.log('updated');

            }

        );

        // reload the page to show new data
        window.location.reload(false);


    }

    // show hide per selected questions
    showHideSelect = (e) => {

        let sodium = this.sodium.current.value;

        if (sodium == 'yes') {
            this.setState({showSodium: 'show-sodium'});
            this.setState({showQuestions: 'hide-questions'});
        } else {
            this.setState({showSodium: 'hide-sodium'});
            this.setState({showQuestions: 'show-questions'});
        }

        this.setState({sodium_consume:sodium});


        let carbs = this.carb.current.value;


        if(carbs == 'yes'){
            this.setState({showCarbs:'show-carbs'});

        }else{
            this.setState({showCarbs:'hide-carbs'});
        }

        this.setState({carb_consume:carbs});


        let fluids = this.fluid.current.value;


        if(fluids == 'yes'){
            this.setState({showFluids:'show-fluids'});

        }else{
            this.setState({showFluids:'hide-fluids'});
        }

        this.setState({fluid_consume:fluids});

        //this.setState({questions:this.questions.current.value});

        console.log(this.race_type.current.value);

        if(this.race_type.current.value === 'single-sport'){
            this.setState({show_est:'hide-est'})
        }else{
            this.setState({show_est:'show-est'})
        }

    }

    startHideSelect = (e)=> {
        this.setState({sodium_consume:'no'});
        this.setState({fluid_consume:'no'});
        this.setState({carb_consume:'no'});
        this.setState({showEst:'hide-est'})

        this.setState({showQuestions:'show-questions'});
        this.setState({showSodium:'hide-sodium'});
        this.setState({showCarbs:'hide-carbs'});


        this.setState({showFluids:'hide-fluids'});



    }



    addRaceSubmit = async (e) => {
        e.preventDefault();

        AddLoadingGif();
        await this.updateRaceData().then(() => {
            this.setState({ loading: false });

        });



    }

    updateRaceData = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;


                let race_type = this.race_type.current.value;
                let race_hours = Math.ceil(Number(this.raceObject.race_time) / 60);
                let bike_hours = Math.ceil(this.bike_est_time.current.value / 60);
                let run_hours = Math.ceil(this.run_est_time.current.value / 60);


                let runArray = [];
                let bikeArray = [];

                let race_start_time = 0;


        if(race_type === 'triathlon') {

            for (let i = 0; i < bike_hours; i++) {
                let race_label = (race_start_time + i) + ' - ' + (race_start_time + i + 1);
                bikeArray.push(race_label);

            }


            for(let i = 0; i < run_hours; i++){
                let race_label = ((race_start_time + i) + ' - ' + (race_start_time + i+1))

                runArray.push(race_label);
            }
        }else{

            for(let i = 0; i < race_hours; i++){
                let race_label = ((race_start_time + i) + ' - ' + (race_start_time + i+1))

                runArray.push(race_label);
            }
        }

            let sodium_questions = this.questions.current.value;
            let sodium = this.sodium.current.value;
            let carb = this.carb.current.value;
            let fluid = this.fluid.current.value;

            let sodium_value = ' ';
            let carb_value = ' ';
            let fluid_value = ' ';

            if(sodium === 'yes') {
                sodium_value = this.sodium_value.current.value;
            }else{
                sodium_value = 0;
            }

            if(carb === 'yes'){
                carb_value = this.carb_value.current.value;
            }else{
                carb_value = this.carb_value.current.value;
            }

            if(fluid_value === 'yes') {
                fluid_value = this.fluid_value.current.value;
            }else{
                fluid_value = 0;
            }

            let bike_est_time = '20';

            if(this.bike_est_time.current.value !== ''){
                bike_est_time = this.bike_est_time.current.value;
            }else{
                bike_est_time = 0;
            }

        await addDoc(collection(db, 'users', userID, 'race-plan'), {
            ['timestamp']:Date.now(),
            ['current_weight']:this.raceObject.current_weight,
            ['race_name']:this.raceObject.race_name,
            ['race_time']:this.raceObject.race_time,
            ['race_temp']:this.raceObject.race_temp,
            ['race_date']:this.raceObject.race_date,
            ['race_humidity']:this.raceObject.race_humidity,
            ['race_type']:race_type,
            ['bike_est_time']:bike_est_time,
            ['run_est_time']:this.run_est_time.current.value,
            ['sodium_consume']: sodium,
            ['sodium_question']:sodium_questions,
            ['carb_consume']: carb,
            ['fluid_consume']: fluid,
            ['sodium_value']: sodium_value,
            ['carb_value']: carb_value,
            ['fluids_value']: fluid_value,
            ['bike']:[],
            ['run']:[],
            ['bike_time_slots']:bikeArray,
            ['run_time_slots']:runArray,
            ['user_edit']:false

        }).then(docRef=>
        {
            console.log(this.sodium);
            console.log(docRef.id);
            addDoc(collection(db, 'users', userID, 'gut-training'), {
                ['race_details']: {id: docRef.id,
                    race_name: this.raceObject.race_name,
                    date: this.raceObject.race_date,
                    time:this.raceObject.race_time,
                    race_type:this.raceObject.race_type,
                    race_temp:this.raceObject.race_temp,
                    race_humidity:this.raceObject.race_humidity,
                },
                ['sodium_consume']: sodium,
                ['carb_consume']: carb,
                ['fluid_consume']: fluid,
                ['sodium_loss_value']: sodium_value,
                ['carb_consume_value']: carb_value,
                ['fluids_value']: fluid_value,
                ['products']: [],
                ['is_paused']:false,
                ['is_completed']:false

            });
        });


        window.location.reload(false);

    }

    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };


    render(){

        return (

            <div className="racePlan">

                <form onSubmit={this.addRaceSubmit}>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="race-name">Race Name</label><br/>
                            <input name="race-name" type="text" onChange={this.raceNameInput}/>
                            <label htmlFor="est-race-time">Estimated Race Duration (min) </label><br/>
                            <input name="est-race-time" type="number" onChange={this.raceTimeInput} ref={this.race_time}/>
                            <label htmlFor="race-humidity">Race Humidity (%)</label><br/>
                            <input name="race-humidity" type="number" onChange={this.raceHumidityInput}/>

                        </div>
                        <div className="col">
                            <label htmlFor="race-temp">Race Temperature (F)</label><br/>
                            <input name="race-temp" type="number" onChange={this.raceTempInput}/>
                            <label htmlFor="race-date">Race Date</label><br/>
                            <input name="race-date" type="date" placeholder="dd-mm-yyyy" onChange={this.raceDateInput}/>
                            <label htmlFor="current-weight">Current Weight (lbs)</label><br/>
                            <input name="current-weight" type="text" onChange={this.currentWeightInput}/>
                        </div>
                        <div className="col">
                            <label htmlFor="race-type">Type of Race</label><br/>
                            <select name="race-type" value={this.state.race_type}
                                    ref={this.race_type} onChange={this.showHideSelect}>
                                <option value="triathlon">Triathlon</option>
                                <option value="single-sport">Single Sport</option>

                            </select>
                            <div className={this.state.show_est}>
                                <label htmlFor="race-est-bike">Estimated Bike Time</label>
                                <input type="text" name="race-est-bike"
                                       ref={this.bike_est_time}/>
                            </div>
                            <div className={this.state.show_est}>
                                <label htmlFor="race-est-run">Estimated Run Time</label>
                                <input type="text" name="race-est-run"
                                       ref={this.run_est_time}/>
                            </div>
                        </div>


                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="sodium-loss">Have you tested sodium loss?</label>
                            <select value={this.state.sodium_consume} ref={this.sodium}
                                    onChange={this.showHideSelect}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>

                            <br/>

                            <div className={this.state.showSodium}>
                                <label htmlFor="sodium-loss-value">Sodium Loss Value (mg/oz)</label><br/>
                                <input name="sodium-loss-value" type="text"
                                       ref={this.sodium_value}/>
                            </div>

                            <div className={this.state.showQuestions}>
                                <label htmlFor="sodium-questions">Do you?</label><br/>
                                <select name="sodium-questions" ref={this.questions}>
                                    <option value="0">Select Your Answer</option>
                                    <option value="a">Rarely notice salt on my clothes, rarely taste salty or crave
                                        salty foods
                                    </option>
                                    <option value="b">Occasionally notice salt on my clothes after more intense or
                                        longer effects
                                    </option>
                                    <option value="c">Almost always see white marks on my clothes after training and
                                        regularly crave salty foods
                                    </option>
                                </select>
                            </div>
                            <br/>
                        </div>
                        <div className="col">

                            <div>
                                <label htmlFor="carb-consumed">Do you currently consume carbohydrates during
                                    training?</label><br/>
                                <select name="carb-consumed" value={this.state.carb_consume} ref={this.carb}
                                        onChange={this.showHideSelect}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>

                            </div>
                            <br/>

                            <div className={this.state.showCarbs}>
                                <label htmlFor="carb-consumed-value">How many carbs/hr? (g)</label><br/>
                                <input name="carb-consumed-value" type="text"
                                       defaultValue={this.state.carb_consume_value} ref={this.carb_value}/>
                            </div>
                            <br/>
                        </div>
                            <div className="col">
                                <label htmlFor="fluid-consume">Do you currently consume fluids during
                                    training</label><br/>
                                <select name="fluid-consume" value={this.state.fluid_consume} ref={this.fluid}
                                        onChange={this.showHideSelect}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>

                            <br/>
                            <div className={this.state.showFluids}>
                                <label htmlFor="fluids-value">How many fluids/hr? (oz)</label><br/>
                                <input name="fluids-value" type="text" defaultValue={this.state.fluid_value}
                                       ref={this.fluid_value}/>
                            </div>
                            <br/>
                        </div>
                    </div>



                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add Race Plan</button>


                </form>
                {this.state.loading ? <Spinner style={{marginBottom: 27}} animation="border"/> : null}

            </div>

        )
    }


}

export default AddRacePlan;